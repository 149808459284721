@import url("tipografia.css");
:root {
  /*Alter*/
  --fonte-master: "Montserrat", Helvetica, Arial, sans-serif;
  --branco: #ffffff;
  --prata: #c0c0c0;
  --prata-claro: #c1c1c1;
  --orange: orange;
  --orange-six: rgb(255, 165, 0, 0.6);
  --orange-dark-btn: rgb(244, 90, 8);
  --contato-enviar: #9f5418;
  --contato-enviar-hover: rgba(244, 57, 0, 0.9);
  --portifolio-clab: #e7e7e7;
  --blog-sidebar: #999999;
  --blog-sidebar-post: #dcdcdc;
  --blog-item-banner: #e1e1e1;
  --hacker-gray: #eeeeee;
  --blog-artigo: #666666;
  --blog-breadcrumb: rgba(11, 249, 238, 0.25);
  --blog-bh-text: #c0c0c0;
  --texto-servico: #554a4a;
  --btn-left: rgba(93, 63, 7, 0.6);
  --btn-font-rigth: rgb(226, 134, 85);
  --ouro-escuro: rgb(45, 43, 43);
  --ouro-escuro-six: rgba(31, 23, 10, 0.6);
  --black-two: rgba(0, 0, 0, 0.2);
  --black-six: rgba(0, 0, 0, 0.6);
  --black-eight: rgba(0, 0, 0, 0.8);
  --blog-item: #111111;
  --black: #000000;
}
html,
body,
#root {
  width: 100%;
  font-family: var(--fonte-master);
  -webkit-font-smoothing: antialiased;
  height: 100%;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none; /*color: #fff;*/
}
#attack,
#blog,
#ethical,
#pentest,
.landscape {
  background: var(--branco);
  padding-top: 6rem;
  padding-bottom: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
} /*---------------------     Blog|Hacker(pages) Banners   -----------------------*/
.principal-page {
  position: relative;
}
.principal-page__text {
  position: absolute;
  left: 0;
  top: 0;
}
.principal-page__logo {
  object-fit: cover;
  width: 100%;
  height: 360px;
  z-index: -1;
}
.principal-page__logo--resize {
  min-height: 360px;
} /*---------------------     Blog|Hacker(pages) Details   -----------------------*/
.breadcrumb-text {
  display: inline-block;
  padding: 35px 40px; /*transform: translate(100px, 100px);*/
  position: relative;
  left: 50px;
  top: 100px;
  color: var(--branco);
  background: var(--blog-breadcrumb); /*rgba(224, 192, 14, 0.25);*/
}
.breadcrumb-text--line {
  display: flex;
  align-items: center;
} /*---------------------     Controle Global Box   -----------------------*/
.mt-4 {
  margin-top: 1.5rem !important;
}
.mt-1 {
  margin-top: 4rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.mb-1 {
  margin-bottom: 4rem !important;
}
.ml-4 {
  margin-left: 4px;
} /*---------------------     Form Contato   -----------------------*/
input[type="text"],
input[type="email"],
input[type="tel"],
select {
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  padding: 10px;
  outline: none;
  border-bottom: 1px solid var(--branco);
  background: transparent;
  width: 100%;
  margin-bottom: 60px;
  color: var(--branco);
  font-size: 14px;
}
select {
  width: 50%;
}
input::placeholder {
  color: var(--branco);
} /*---------------------     Controle Owl-Carousel | Globo   -----------------------*/
.tecnology-carousel {
  border-top: 1px solid #ebebeb;
  padding: 50px 0;
  margin-top: 3rem;
}
.owl-stage {
  display: flex;
  align-items: center;
} /*---------------------     Medias - Query     1-Tipografia   -----------------------*/
@media screen and (max-width: 767px) {
  .titulo {
    font-weight: 700;
  }
  .subtitulo {
    font-weight: 500;
  }
  .titulo-portifolio {
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    margin-bottom: 10px;
  }
  .subtitulo-portifolio {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: -0.04em;
  }
  .text-portifolio {
    font-size: 1.4rem;
  }
  .tecnology-carousel {
    padding: 20px 0;
    margin-top: 1.5rem;
  }
}
