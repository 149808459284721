@import url("https://fonts.googleapis.com/css2?family=Elsie+Swash+Caps:wght@900&family=Montserrat:ital,wght@0,100;0,400;0,600;0,700;0,800;0,900;1,100;1,300&family=Open+Sans:wght@300;400;700&family=Roboto:wght@100;300;400;700&display=swap");

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#preloder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #555;
}

.loader {
  width: 50px;
}
