footer {
  background: url("../img/footer/bg.jpg");
  text-align: center;
  padding: 40px 0;
  position: relative;
}
.cabecalho__footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rodape__redes {
  position: absolute;
  right: 0;
  margin-right: 20px;
}
.rodape__footer {
  display: flex;
  list-style: none;
}
.rodape__footer--mini {
  width: 28px;
  margin: 4px 8px 0px 8px;
}
.rodape__footer--mini:hover {
  cursor: pointer;
}
.rodape__text {
  color: var(--branco);
  font-size: 13px;
  margin: 2px;
}
.copyright {
  margin-top: 20px;
}
.video {
  width: 80%;
  height: 500px;
  margin: 1em auto;
}
